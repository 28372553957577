import { GetServerSideProps } from 'next'

import { SWRConfig } from 'swr'
import RoadmapPage from '../components/RoadmapPage'
import { getHTTPProtocol, redirectToRefresh } from '../lib/subdomain'
import SeoMetaPublic from '../components/SeoMetaPublic'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { getClientIp } from '../lib/getClientIp'
import {
  defaultSWRConfig,
  determineSSRTheme,
  getRoadmapDefaultFilters,
  objectToMongooseQueryString,
} from '@/lib/utils'
import { axiosGetRequest, identifySSOUser } from '.'
import { unstable_serialize } from 'swr/infinite'
import { submissionsGetKey } from '@/data/submission'
import { IOrganization } from '@/interfaces/IOrganization'
import { useHydrateAtoms } from 'jotai/utils'
import { submissionActiveFilterURIAtom } from '@/atoms/submissionAtom'
import { mongooseQueryStringToObject } from '@/lib/utils'
import { updateFilters } from '@/components/FilterSyncer'
import { ISubmissionFilters } from '@/interfaces/ISubmission'

export const getServerSideProps: GetServerSideProps = async (context) => {
  const host = context.req.headers['x-forwarded-host'] || context.req.headers['host']
  const cookie = context.req.headers.cookie

  const ip = getClientIp(context)
  const http = getHTTPProtocol()
  const jwt = context.query?.jwt

  const [org, user, identifiedUser] = await Promise.allSettled([
    axiosGetRequest(`${http}${host}/api/v1/organization`, cookie, ip, jwt),
    axiosGetRequest(`${http}${host}/api/v1/user`, cookie, ip, jwt),
    identifySSOUser(http, host, cookie, ip, jwt),
  ])

  const fallback = {}
  let mongoURIs: any = {}
  let initialFilterUri = null
  let initialFiltersForFrontend = null
  let mode = null

  if (
    context.query.mode &&
    ['status', 'quarterly', 'monthly'].includes(context.query.mode as string)
  ) {
    mode = context.query.mode
  }

  if (org.status === 'fulfilled') {
    Object.assign(fallback, { '/v1/organization': org.value.data })
    const orgData = org.value.data

    // Parse filters from URL
    const filters = mongooseQueryStringToObject(
      context?.req?.url ? decodeURIComponent(context?.req?.url?.split('?')[1]) : ''
    )

    const defaultFiltersWithoutStatus = getRoadmapDefaultFilters(orgData, undefined)

    if (!defaultFiltersWithoutStatus.sortBy) {
      defaultFiltersWithoutStatus.sortBy = 'upvotes:desc'
    }

    initialFilterUri = objectToMongooseQueryString(
      [...defaultFiltersWithoutStatus.advancedFilters, ...filters],
      {
        sortBy: defaultFiltersWithoutStatus.sortBy,
        inReview: defaultFiltersWithoutStatus.inReview,
        includePinned: defaultFiltersWithoutStatus.includePinned,
        q: defaultFiltersWithoutStatus.q,
      },
      defaultFiltersWithoutStatus,
      orgData,
      false,
      true
    ).backendQuery

    initialFiltersForFrontend = updateFilters(
      defaultFiltersWithoutStatus,
      filters,
      defaultFiltersWithoutStatus
    )

    const responses = await Promise.allSettled(
      orgData?.roadmapStatuses?.map((stat: string) => {
        const defaultFilters = getRoadmapDefaultFilters(orgData, stat, filters)

        // Combine default filters with URL filters
        const queryString = objectToMongooseQueryString(
          defaultFilters.advancedFilters,
          {
            sortBy: defaultFilters.sortBy || 'upvotes:desc',
            inReview: defaultFilters.inReview,
            includePinned: defaultFilters.includePinned,
            q: defaultFilters.q,
          },
          defaultFilters,
          orgData,
          false,
          true
        ).backendQuery

        mongoURIs[stat] = queryString

        return axiosGetRequest(
          `${http}${host}/api/v1/submission/?page=1&` + queryString,
          cookie,
          ip,
          jwt
        )
      })
    )

    responses.forEach((res, index) => {
      if (res.status === 'fulfilled') {
        Object.assign(fallback, {
          [unstable_serialize((pageIndex: number, previousPageData: any) => {
            return submissionsGetKey(
              pageIndex,
              previousPageData,
              mongoURIs[orgData.roadmapStatuses[index]]
            )
          })]: [res?.value?.data],
        })
      }
    })
  } else {
    return redirectToRefresh(context.req, '/roadmap')
  }

  if (user.status === 'fulfilled') {
    Object.assign(fallback, { '/v1/user': user.value.data })
  }

  if (identifiedUser?.status === 'fulfilled') {
    if (identifiedUser?.value?.data?.user) {
      Object.assign(fallback, { '/v1/user': identifiedUser.value.data.user })
    }
  }

  let translate = {}
  if (context.locale) {
    translate = await serverSideTranslations(context.locale, ['common'])
  }

  return {
    props: {
      fallback,
      org: org.value.data,
      defaultTheme: determineSSRTheme(org?.value?.data, context),
      queryParams: context.query,
      backendQueryString: initialFilterUri,
      initialFiltersForFrontend: JSON.stringify(initialFiltersForFrontend),
      mode,
      ...translate,
    },
  }
}

const Roadmap: React.FC<{
  fallback: any
  org: IOrganization
  backendQueryString: string
  initialFiltersForFrontend: string
  mode: 'status' | 'quarterly' | 'monthly' | null
}> = ({ fallback, org, backendQueryString, initialFiltersForFrontend, mode }) => {
  const { t } = useTranslation()
  useHydrateAtoms([[submissionActiveFilterURIAtom, backendQueryString]])

  return (
    <SWRConfig value={{ fallback, ...defaultSWRConfig }}>
      <SeoMetaPublic
        page={t('roadmap')}
        ogImageProps={
          (org &&
            !org.settings.private && {
              company: {
                name: org?.displayName,
                logo: org?.picture,
                themeColor: org?.color,
                themeLinePosition: 'bottom',
              },
              title: t('roadmap'),
              description: "See what's coming next and vote for your favorite ideas.",
              // category: t('roadmap'),
              type: 'root',
            }) ||
          undefined
        }
      />
      <RoadmapPage
        mode={mode}
        initialFiltersForFrontend={
          JSON.parse(initialFiltersForFrontend || '{}') as ISubmissionFilters
        }
      />
    </SWRConfig>
  )
}

export default Roadmap
