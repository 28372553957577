import { useEffect } from 'react'
import MainRoadmapView from './MainRoadmapView'
import PublicBoardWrapper from './PublicBoardWrapper'
import { useAtom } from 'jotai'
import { fullRoadmapAtom } from '@/atoms/orgAtom'
import { useRouter } from 'next/router'
import { submissionActiveFilterURIAtom } from '@/atoms/submissionAtom'
import { QueryEntry } from './MainFilterDropdown'
import { ISubmissionFilters } from '@/interfaces/ISubmission'

interface RoadmapPageProps {
  initialFiltersForFrontend: ISubmissionFilters
  mode: 'status' | 'quarterly' | 'monthly' | null
}

const RoadmapPage: React.FC<RoadmapPageProps> = ({ initialFiltersForFrontend, mode }) => {
  const [isFullRoadmap, setIsFullRoadmap] = useAtom(fullRoadmapAtom)
  const router = useRouter()

  useEffect(() => {
    if (router.query?.fullScreenRoadmap === 'true' && !isFullRoadmap) {
      setIsFullRoadmap(true)
    } else if (router.query?.fullScreenRoadmap === 'false' && isFullRoadmap) {
      setIsFullRoadmap(false)
    }
  }, [router?.query, isFullRoadmap, setIsFullRoadmap])

  return (
    <PublicBoardWrapper wide={true}>
      <MainRoadmapView
        enableRedirection={true}
        initialFiltersForFrontend={initialFiltersForFrontend}
        mode={mode}
      />
    </PublicBoardWrapper>
  )
}

export default RoadmapPage
